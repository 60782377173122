
import * as bunnyRuntime$zRs93xcXYP from '/srv/nodejs/node_modules/@nuxt/image/dist/runtime/providers/bunny'
import * as ipxRuntime$5PYxfBKcWf from '/srv/nodejs/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "img.youtube.com",
    "i.vimeocdn.com"
  ],
  "alias": {
    "/youtube": "https://img.youtube.com",
    "/vimeo": "https://i.vimeocdn.com"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['bunny']: { provider: bunnyRuntime$zRs93xcXYP, defaults: {} },
  ['ipx']: { provider: ipxRuntime$5PYxfBKcWf, defaults: {"maxAge":2592000} }
}
        